<template>
  <img style="max-height:700px;object-fit:contain" :src="require('../assets/Giraffa_camelopardalis_angolensis.jpg')" />
  <a href="https://myexternalip.com/">TestSiteMats</a>
</template>

<script>
import { defineComponent } from 'vue'

// Components

export default defineComponent({
  name: 'HomeView',

  components: {

  }
})
</script>
