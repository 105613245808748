<template>
  <v-app-bar>
    <img class="mx-3" style="max-height:100%" :src="require('../assets/SooSLogo.png')" />
    <h1>SooS Telling</h1>
  </v-app-bar>
</template>

<script>

export default {
  name: 'NavBar',

  data: () => ({

  })
}
</script>
